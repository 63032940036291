








































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getIntegralConfig, exchangeIntegral } from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import Goods from './components/Goods.vue'
import { isIOS } from '@/common/utils'
@Component({
  name: 'creditsExchange',
  components: {
    Goods
  }
})
export default class extends Vue {
  num: any = '0'
  @Watch('num')
  valueChange(val: string | number) {
    if (Number(val) > this.maxCoin) {
      this.num = this.maxCoin
    }
  }
  isIOS = isIOS
  integral: any = 0
  coin: any = 0
  get userId() {
    return CommonModule.userId
  }
  changeIntegralRate = 0
  changeCoinRate = 0
  get maxCoin() {
    return Math.floor(
      this.integral * (this.changeCoinRate / this.changeIntegralRate)
    ) || 0
  }
  activeTab = 1
  showGoods = false
  goVip() {
    this.$router.push({ name: 'vip' })
  }
  tabChange(index: number) {
    this.activeTab = index
  }
  async getConfig() {
    const params = {
      uid: this.userId
    }
    const { data } = await getIntegralConfig(params)
    this.integral = data.integral
    this.coin = data.coin
    this.changeIntegralRate = data.changeIntegralRate
    this.changeCoinRate = data.changeCoinRate
    // 1展示，0不展示
    this.showGoods = data.hiddenGoods == 1
    this.num = Math.floor(
      this.integral * (this.changeCoinRate / this.changeIntegralRate)
    )
  }

  async commitExchange() {
    if (!this.num) {
      this.$toast(this.$t('请输入兑换数量'))
      return
    }
    if (this.num > this.maxCoin) {
      this.$toast(this.$t('兑换数量超过最大值'))
      return
    }
    const reg = /^[1-9]\d*$/
    if (!reg.test(this.num)) {
      this.$toast(this.$t('请输入正整数'))
      return
    }
    const parmas = {
      uid: this.userId,
      changeCoin: this.num,
      useIntegral: Math.floor(
        (this.num * this.changeIntegralRate) / this.changeCoinRate
      )
    }
    const { data } = await exchangeIntegral(parmas)
    this.getConfig()
    if (data.result == 1) {
      this.$toast(this.$t('兑换成功'))
      this.num = undefined
    } else {
      this.$toast(this.$t('兑换失败'))
    }
  }
  goRecord() {
    this.$router.push({ name: 'gameRecord' })
  }
  goOrderList() {
    this.$router.push({ name: 'exchangeRecord' })
  }

  mounted() {
    this.getConfig()
  }
  activated() {
    console.log('activated')
    this.getConfig()
  }
}
