
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  @Prop({
    default: 1
  })
  activeTab!: number
  @Watch('activeTab', { immediate: true, deep: true })
  onActiveTabChange(val: number) {
    if (val === 2) {
      ;(this.$refs.swipe as any).resize()
    }
  }
  @Prop({
    default: () => {
      return []
    }
  })
  list!: Array<any>
  language = getAppLanguage()
  // list: any = [
  //   {
  //     id: 1,
  //     type: 1,
  //     title: '商品兑换规则已更新，点击查看',
  //     jumpUrl: ''
  //   },
  //   {
  //     id: 2,
  //     type: 2,
  //     title: '商品兑换规则已更新，点击查看2',
  //     jumpUrl: ''
  //   },
  //   {
  //     id: 3,
  //     type: 3,
  //     title: '商品兑换规则已更新，点击查看3',
  //     jumpUrl: ''
  //   },
  //   {
  //     id: 4,
  //     type: 4,
  //     title: '商品兑换规则已更新，点击查看4',
  //     jumpUrl: ''
  //   },
  //   {
  //     id: 5,
  //     type: 5,
  //     title: '商品兑换规则已更新，点击查看5',
  //     jumpUrl: ''
  //   }
  // ]
  // list2 = [
  //   {
  //     id: 2,
  //     sortNo: 1,
  //     titleUg: 'daass',
  //     title: '大大丰富',
  //     jumpUrl: 'www.qq.com',
  //     createTime: '2023-08-17 17:19:46'
  //   },
  //   {
  //     id: 3,
  //     sortNo: 2,
  //     titleUg: '少时诵诗书',
  //     title: '懂的都懂',
  //     jumpUrl: '',
  //     createTime: '2023-08-17 17:23:16'
  //   }
  // ]
  handleClick(item: any) {
    if (item.jumpUrl) {
      location.href = item.jumpUrl
    }
  }
}
