







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getExchangeGoodsList } from '@/pages/videoGame/api'
import { getAppLanguage } from '@/common/utils'
import MallNotice from './MallNotice.vue'
@Component({
  components: {
    MallNotice
  }
})
export default class extends Vue {
  @Prop({
    default: 1
  })
  activeTab!: number
  list: any = []
  noticeList: any = []
  loading = false
  finished = false
  refreshing = false
  language = getAppLanguage()
  pageInfo = {
    pageSize: 20,
    currPage: 0,
    total: 0
  }
  async getList(type?: string) {
    const params = {
      page: type == 'refresh' ? 1 : this.pageInfo.currPage + 1,
      limit: this.pageInfo.pageSize
    }
    const { data } = await getExchangeGoodsList(params)
    if (this.noticeList.length == 0) {
      this.noticeList = data.notices
    }
    if (this.refreshing) {
      this.list = []
      this.refreshing = false
    }
    this.loading = false
    this.list.push(...data.config.goodsList)
    this.pageInfo = data.pageInfo

    if (this.list.length >= this.pageInfo.total) {
      this.finished = true
    }
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true
    this.getList('refresh')
  }
  goDetail(item: any) {
    this.$router.push({
      name: 'goodsDetail',
      query: {
        id: item.id
      }
    })
  }
  mounted() {
    this.getList()
  }
}
